import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

const BigButton = (props) => {
  const image = getImage(props.photo)

  return props.external ? (
    <a href={props.link} className="big-cta-link" target="_blank" rel="noreferrer">
      <GatsbyImage image={image} alt="" />
      <div className="big-cta-btn">
        <span>
          {props.name} <FontAwesomeIcon icon={faExternalLinkAlt} />
        </span>
      </div>
    </a>
  ) : (
    <Link to={props.link} className="big-cta-link">
      <GatsbyImage image={image} alt="" />
      <div className="big-cta-btn">
        {props.name}
      </div>
    </Link>
  )
}

export default BigButton