import React, { useEffect } from "react"
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import netlifyIdentity from 'netlify-identity-widget'

import BigButton from '../components/BigButton/BigButton'
import Header from '../components/Header/Header'
import Layout from '../components/Layout/Layout'

import '../styles/styles.css'
import '../styles/home.css'

const Home = ({ data }) => {
  useEffect(() => {
    netlifyIdentity.init({})
  }, [])
  return (
    <Layout title="Voter Resources">
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={data.markdownRemark.frontmatter.headerImage.photo} alt={data.markdownRemark.frontmatter.headerImage.altText}>{data.markdownRemark.frontmatter.heading}</Header>
      <div className="main">
        <div className="flex-row cta-row">
          {data.markdownRemark.frontmatter.buttons.map(button => (
            <BigButton link={button.link} name={button.name} photo={button.photo} external={button.external} key={button.link} />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query VoterResourcesQuery {
  markdownRemark(frontmatter: {title: {eq: "Voter Resources"}}) {
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
      buttons {
        name
        link
        external
        photo {
          childImageSharp {
            gatsbyImageData(transformOptions: {fit: COVER, cropFocus: ATTENTION})
          }
        }
      }
    }
  }
}
`
export default Home